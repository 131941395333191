import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Aviso } from './Aviso';

export const Inicio = () => {

    const [nif, setNif] = useState('');
    const [warned, setWarned] = useState(0);
    const [open, setOpen] = useState(true);

    const location = useLocation()
    const navigate = useNavigate()

    // Se ejecuta al cargar este componente
    useEffect(() => {
        fetchInicio();
        setNif(location.state.nif);
    }, []);

    function fetchInicio() {
        fetch('/api/inicio')
            .then(async response => {
                if (!response.ok) navigate('/');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const fetchLogout = e => {
        e.preventDefault();

        fetch('/api/logout', { method: 'POST' })
            .then(async response => {
                if (response.ok) navigate('/');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const getSeparador = async e => {
        e.preventDefault();

        try {
            const response = await fetch(`api/descargaSeparadores/${nif}`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Error al descargar el archivo');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'separador_' + nif;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const getCurriculum = async e => {
        e.preventDefault();
        
        try {
            const response = await fetch(`api/descargaCurriculum/${nif}`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Error al descargar el archivo');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'curriculum_' + nif;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <>
            { /* Formulario para editar la pelicula guardada */}
            {warned === 0 && (
                <Aviso open={open} setOpen={setOpen} setWarned={setWarned} nif={nif} />
            )}
            <div className='navbar'>
                <nav>
                    <ul>
                        <li>
                            <p className='btnLogout' onClick={fetchLogout} >Tanca sessió</p>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className='form-container-inicio'>
                <form className='form-container-dw' onSubmit={getSeparador} >
                    <h2>Descàrrega de separadors</h2>
                    <button className='btnfci' type="submit">DESCARREGAR</button>
                </form>
                <form className='form-container-dw' onSubmit={getCurriculum} >
                    <h2>Descàrrega de currículum</h2>
                    <button className='btnfci' type="submit">DESCARREGAR</button>
                </form>
            </div>
        </>
    )
}
